const slides = [
  {
    source: require("../../assets/images/web_development.png"),
    content: `Web Development`,
    className: `web-development`
  },
  {
    source: require("../../assets/images/mobile.png"),
    content: `Mobile Development iOS and Android`,
    className: `mobile`
  },
  {
    source: require("../../assets/images/web_design.png"),
    content: `Web Design`,
    className: `web-design`
  },

  {
    source: require("../../assets/images/software.png"),
    content: `Software Programming`,
    className: `software-programming`
  },
  {
    source: require("../../assets/images/patent.png"),
    content: `Patent Litigition & Consulting`,
    className: `patent`
  }
];

const contactInfo = [
  {
    icon: "far fa-envelope",
    label: "info@sabreinwork.com"
  }
];

const bannerDescContent = [
  `We provide innovative and breakthrough development for web, software, and mobile applications for android and iOS.`,
];

export { slides, contactInfo, bannerDescContent };
