import { Component, Vue } from "vue-property-decorator";
import HeroContentComponent from "./templates/HeroContent.vue";

import { bannerDescContent, contactInfo } from "../utils/sbr-utils";
import { Fragment } from "vue-fragment";

@Component({
  mixins: [HeroContentComponent],
  name: "HeroContent",
  props: ["logo"],
  components: {
    Fragment
  }
})
export default class HeroContent extends Vue {
  data() {
    return {
      contactInfo,
      bannerDescContent
    };
  }
}
