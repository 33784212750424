import { Component, Vue } from "vue-property-decorator";
// import { Fragment } from "vue-fragment";
import HomePage from "./templates/Home.vue";

import "swiper/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";

import SbrSlider from "../components/SbrSlider";
import HeroContent from "../components/HeroContent";
import SbrModal from "../components/SbrModal";
// require("dotenv").config({ path: ".env" });

@Component({
  mixins: [HomePage],

  components: {
    SbrModal,
    SbrSlider,
    HeroContent
  }
})
export default class Home extends Vue {
  $refs!: {
    mySwiper: any;
  };

  data() {
    return {
      cover: require("../../assets/images/cover.png"),
      logoImg: require("../../assets/images/brand.png"),
      logoImgMobile: require("../../assets/images/brand-mobile.png"),
      isOpen: false,
      isMobile: false,
    };
  }

  startBtn() {
    this.$data.isOpen = true;
  }

  mounted() {
    const $media = window.matchMedia("(max-width: 700px)");
    this.$data.isMobile = $media.matches ? true : false;
  }
}
