import { Component, Vue } from "vue-property-decorator";
import SbrSliderComponent from "./templates/SbrSlider.vue";

import { slides } from "../utils/sbr-utils";
import { swiper, swiperSlide } from "vue-awesome-swiper";

@Component({
  mixins: [SbrSliderComponent],
  name: "SbrSlider",
  props: [],
  components: {
    swiper,
    swiperSlide
  }
})
export default class SbrSlider extends Vue {
  $refs!: {
    mySwiper: any;
  };

  data() {
    return {
      slides,
      initialCaptionDelay: `1000`,
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 5000
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        }
      }
    };
  }

  get swiper() {
    return this.$refs.mySwiper.swiper;
  }

  get activeIndex() {
    const activeIndex = this.swiper.activeIndex;
    const slideContent = this.swiper.slides[activeIndex];
    return slideContent.querySelector(".aos-ani").classList;
  }

  slideChangeTransitionStartEvent() {
    this.$data.initialCaptionDelay = 0;
    this.activeIndex.remove("aos-animate");
  }

  slideChangeTransitionEndEvent() {
    this.activeIndex.add("aos-animate");
  }
}
