import { Component, Vue } from "vue-property-decorator";
import SbrModalComponent from "./templates/SbrModal.vue";
import TextInput from "../components/TextInput";
import TextArea from "../components/TextArea";

import VueRecaptcha from "vue-recaptcha";
import { ValidationProvider, ValidationObserver } from "vee-validate";
require("../utils/sbr-vee-validate");
import axios from "axios";

require("dotenv").config({ path: ".env" });

@Component({
  mixins: [SbrModalComponent],
  name: "SbrModal",
  props: ["is-open"],
  components: {
    VueRecaptcha,
    ValidationProvider,
    ValidationObserver,
    TextInput,
    TextArea
  }
})
export default class SbrModal extends Vue {
  $refs!: {};
  $swal!: any;

  data() {
    return {
      siteKey: "you recaptcha site key",
      sendIcon: "fa-paper-plane",
      inputMessage: "",
      apiSubmitUrl: 'https://cors-anywhere.herokuapp.com/https://script.google.com/macros/s/AKfycbwxLlMt7z_0khu5ckHhHzJKqrGF-1WBKLvBX4ek5DIfhlyS3nZbxZTJ/exec',
      dataInputs: {
        name: "",
        email: "",
        service: "",
        mobile_number: "",
        message: ""
      }
    };
  }

  get formInputs() {
    return this.$data.dataInputs;
  }

  submitForm() {
    this.$data.sendIcon = "fa-spinner fa-spin";
    var temp = this.$data.dataInputs;
    try {
      const $jax: any = axios({
        method: "post",
        url: this.$data.apiSubmitUrl,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: Object.keys(temp).map(function(key) { //needed for query string format
                return encodeURIComponent(key) + '=' + encodeURIComponent(temp[key])
              }).join('&')
      });
      $jax.then($res => {
        if ($res.status === 200) {
          this.$swal.fire({
            icon: "success",
            title: $res.data.message,
            showConfirmButton: false,
            timer: 3000
          });
          this.$emit("close-modal");
        }
        this.$data.sendIcon = "fa-paper-plane";
      });
    } catch (error) {
      console.log("message", error.message, "error", error);
      this.$swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong! Please try again"
      });
      this.$data.sendIcon = "fa-paper-plane";
    }
  }
}
