import { Component, Vue } from "vue-property-decorator";
import TextAreaComponent from "./templates/TextArea.vue";
import { ValidationProvider } from "vee-validate";
import { Fragment } from "vue-fragment";

@Component({
  mixins: [TextAreaComponent],
  name: "TextArea",
  props: [
    "label",
    "id",
    "name",
    "value",
    "placeholder",
    "provider-name",
    "rules"
  ],
  components: {
    ValidationProvider,
    Fragment
  }
})
export default class TextArea extends Vue {
  $refs!: {};

  data() {
    return {};
  }
}
