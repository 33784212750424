









import { Component, Vue } from "vue-property-decorator";
// import Home from "./app/components/Home.vue";
// import Navbar from "./app/components/Navbar";
// import FooterComponent from "./app/components/Footer";
// import Footer from "./app/components/Footer";

@Component({
  components: {
    // Navbar,
    // FooterComponent
    // FooterConponent: Footer
  }
})
export default class App extends Vue {
  // $route.meta
}
