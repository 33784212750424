import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import routes from "./routes";
import "./assets/scss/app.scss";

import VueLazyload from "vue-lazyload";

import AOS from "aos";
import "aos/dist/aos.css";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

AOS.init();

Vue.config.productionTip = false;

Vue.use(VueSweetalert2);

Vue.use(VueRouter);

Vue.use(VueLazyload, {
  preLoad: 1.3,
  // error: "./assets/images/blur.jpg",
  // loading: "./assets/images/blur.jpg",
  attempt: 1
});

const router = new VueRouter({
  mode: "history",
  routes
});

new Vue({
  render: h => h(App),
  router
}).$mount("#app");
