import { Component, Vue } from "vue-property-decorator";
import TextInputComponent from "./templates/TextInput.vue";
import { ValidationProvider } from "vee-validate";

@Component({
  mixins: [TextInputComponent],
  name: "TextInput",
  props: [
    "label",
    "type",
    "id",
    "name",
    "placeholder",
    "value",
    "provider-name",
    "rules"
  ],
  components: {
    ValidationProvider
  }
})
export default class TextInput extends Vue {
  $refs!: {};

  data() {
    return {};
  }
}
